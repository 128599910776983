<nav class="tip-list-nav px-24">
  <div
    (click)="checklistComponentService.handleTabClick(ChecklistTab.UNARCHIVED)"
    [class.tip-list-nav__tab--current]="checklistComponentService.currentTab$() === ChecklistTab.UNARCHIVED"
    class="tip-list-nav__tab pointer py-16 mr-16"
  >
    En cours ({{ checklistComponentService.unarchivedTipsCount$() }})
  </div>
  <div
    (click)="checklistComponentService.handleTabClick(ChecklistTab.ARCHIVED)"
    [class.tip-list-nav__tab--current]="checklistComponentService.currentTab$() === ChecklistTab.ARCHIVED"
    class="tip-list-nav__tab py-16 pointer"
  >
    Terminés ({{ checklistComponentService.archivedTipsCount$() }})
  </div>
</nav>

<div class="tip-list-content pb-32 px-24">
  <div *ngIf="checklistComponentService.currentTab$() === ChecklistTab.UNARCHIVED" @inOutFromLeft>
    <div
      *ngIf="checklistComponentService.unarchivedTipsCategories$().length > 0; else emptyUnarchivedTips"
      [@.disabled]="checklistComponentService.areTipAnimationsDisabled$()"
    >
      <h3 class="mt-32 mb-24 mx-8">Bien démarrer votre entreprise</h3>
      <dougs-checklist-category
        *ngFor="
          let category of checklistComponentService.unarchivedTipsCategories$();
          trackBy: 'title' | trackBy;
          let isLast = last
        "
        [category]="category"
        [isLast]="isLast"
        [@slideInOut]="{
          value: ':leave',
          params: {
            duration: checklistComponentService.ANIMATION_DURATION,
            position: '100%',
          },
        }"
      />
    </div>
  </div>
  <div *ngIf="checklistComponentService.currentTab$() === ChecklistTab.ARCHIVED" @inOutFromRight>
    <div
      *ngIf="checklistComponentService.archivedTipsCategories$().length > 0; else emptyArchivedTips"
      [@.disabled]="checklistComponentService.areTipAnimationsDisabled$()"
    >
      <h3 class="mt-32 mb-24 mx-8">Conseils terminés</h3>
      <dougs-checklist-category
        *ngFor="
          let category of checklistComponentService.archivedTipsCategories$();
          trackBy: 'title' | trackBy;
          let isLast = last
        "
        [category]="category"
        [isLast]="isLast"
        [@slideInOut]="{
          value: ':leave',
          params: {
            duration: checklistComponentService.ANIMATION_DURATION,
            position: '-100%',
          },
        }"
      />
    </div>
  </div>
</div>

<ng-template #emptyUnarchivedTips>
  <div class="mt-32">
    <dougs-blank-state [maxWidth]="32" illustration="party-popper">
      <h6 class="my-8">Bravo !</h6>
      <p class="small">Vous êtes maintenant prêt(e) pour gérer sereinement votre entreprise.</p>
    </dougs-blank-state>
  </div>
</ng-template>

<ng-template #emptyArchivedTips>
  <div class="mt-32">
    <dougs-blank-state [maxWidth]="32" illustration="palmtree">
      <h6 class="my-8">Aucun conseil terminé</h6>
      <p class="small">Une fois un conseil traité, marquez-le comme terminé.</p>
    </dougs-blank-state>
  </div>
</ng-template>
