import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { UserMenuComponentService } from './user-menu.component.service';

@Injectable()
export class SidebarCompactComponentService {
  isHovered: WritableSignal<boolean> = signal(false);
  isHovered$: Signal<boolean> = this.isHovered.asReadonly();

  isCompactUnfolded$: Signal<boolean> = computed(
    () => this.isHovered$() || this.userMenuComponentService.userMenuOpened$(),
  );

  constructor(public readonly userMenuComponentService: UserMenuComponentService) {}

  onMouseEnter(): void {
    this.isHovered.set(true);
  }

  onMouseLeave(): void {
    this.isHovered.set(false);
  }
}
