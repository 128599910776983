import { EventEmitter, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, filter, map, Observable } from 'rxjs';
import { RoutingService } from '@dougs/core/routing';
import { NavElement } from '../../dto';

@Injectable()
export class NavigationItemComponentService {
  private readonly _navElement: BehaviorSubject<NavElement | undefined> = new BehaviorSubject<NavElement | undefined>(
    undefined,
  );
  private readonly _isOpen: WritableSignal<boolean> = signal<boolean>(false);

  navElement$: Observable<NavElement | undefined> = this._navElement.asObservable();
  isOpen$: Signal<boolean> = this._isOpen.asReadonly();

  routerNavigationEnd$: Observable<NavigationEnd> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
  );

  checkRouteActive$: Observable<void> = combineLatest([this.navElement$, this.routerNavigationEnd$]).pipe(
    filter((object): object is [NavElement, NavigationEnd] => !!object[0]),
    map(([navElement, _]) => this.isRouteActive(navElement.url)),
  );

  hideMenuEmitter: EventEmitter<void> = new EventEmitter<void>();

  setNavElement(navElement: NavElement): void {
    this._navElement.next(navElement);
    this.isRouteActive(navElement.url);
  }

  constructor(
    private readonly router: Router,
    private readonly routingService: RoutingService,
  ) {}

  handleNavElementClick(navElement: NavElement): void {
    if (!navElement.lockedOpen) {
      if (navElement.subElements && navElement.subElements.length > 0) {
        this._isOpen.update((isOpen) => !isOpen);
      } else if (navElement.url) {
        void this.router.navigate([this.routingService.createUrl(navElement.url)]);
        this.hideMenuEmitter.emit();
      }
    }
  }

  private isRouteActive(url: string[]): void {
    const isActive: boolean = this.router.isActive(this.routingService.createUrl(url), {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
    this._isOpen.set(isActive);
  }
}
