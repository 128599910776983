<dougs-pill [type]="category.color" class="ml-8">{{ category.title }}</dougs-pill>
<dougs-checklist-tip
  *ngFor="let tip of category.tips; trackBy: 'type' | trackBy; let first = first"
  [tip]="tip"
  [isFirst]="first"
  (tipClicked)="checklistComponentService.openTip(tip)"
  (checkboxClicked)="checklistComponentService.handleTipClick(tip)"
  class="mt-8"
  [@slideInOut]="{
    value: ':leave',
    params: { duration: checklistComponentService.ANIMATION_DURATION, position: !!tip.archivedAt ? '-100%' : '100%' },
  }"
/>
<dougs-divider *ngIf="!isLast" class="my-16 mx-8" />
