@if (navigationItemComponentService.checkRouteActive$ | async) {}
@if (navigationItemComponentService.navElement$ | async; as navElement) {
  @if (navElement.hasSeparator) {
    <dougs-divider class="my-16" />
  }
  @if (navElement.subElements?.length > 0) {
    <a
      (click)="navigationItemComponentService.handleNavElementClick(navElement)"
      [ngClass]="{
        admin: navElement.isAdmin,
        'locked-open': navElement.lockedOpen,
        pointer: !navElement.lockedOpen,
      }"
      class="py-8 px-12"
    >
      {{ navElement.label }}
      @if (!navElement.lockedOpen) {
        <i [class.rotated]="navigationItemComponentService.isOpen$()" class="fal fa-chevron-down"></i>
      }
    </a>
  } @else {
    <a
      (click)="hideMenu.emit()"
      [routerLink]="navElement.url | createUrl | async"
      [class.admin]="navElement.isAdmin"
      [class.disabled]="navElement.disabled | async"
      routerLinkActive="active"
      class="py-8 px-12"
    >
      {{ navElement.label }}
    </a>
  }

  @if (navElement.subElements?.length > 0) {
    <div class="sub-nav-container ml-12" [@slideUpDown]="navigationItemComponentService.isOpen$() ? 'down' : 'up'">
      @for (subElement of navElement.subElements; track subElement.label) {
        @if (subElement.shouldShow | async) {
          <a
            (click)="hideMenu.emit()"
            [routerLink]="subElement.url | createUrl | async"
            [class.admin]="subElement.isAdmin"
            [class.disabled]="navElement.disabled | async"
            routerLinkActive="active"
            class="sub-element py-8 px-12"
          >
            {{ subElement.label }}
          </a>
        }
      }
    </div>
  }
}
