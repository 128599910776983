<dougs-logo color="dark" width="116" />
<h5 class="mt-8 mb-24">
  Renseignez les identifiants <span class="color-primary">Dougs</span> du compte que vous souhaitez lier à l'entreprise
  Parodox
</h5>
<ng-container [formGroup]="addCompanyModalComponentService.formGroup">
  <dougs-form-field>
    <label dougsFormFieldLabel for="email">Adresse e-mail</label>
    <input dougsFormFieldControl formControlName="email" type="email" id="email" placeholder="Adresse e-mail" />
    <span *ngIf="formService.isControlInvalid(addCompanyModalComponentService.email)" dougsFormFieldError>
      Vous devez renseigner un e-mail valide
    </span>
  </dougs-form-field>
  <dougs-form-field>
    <label dougsFormFieldLabel for="password">Mot de passe</label>
    <input dougsFormFieldControl formControlName="password" type="password" id="password" placeholder="********" />
    <span *ngIf="formService.isControlInvalid(addCompanyModalComponentService.password)" dougsFormFieldError>
      Vous devez renseigner un mot de passe
    </span>
  </dougs-form-field>
</ng-container>
