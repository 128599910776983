import { Injectable } from '@angular/core';
import { differenceInDays } from 'date-fns';
import { combineLatest, concatMap, lastValueFrom, map, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { ModalService } from '@dougs/ds';
import { PendingBillingInvoice } from '@dougs/subscription/dto';
import { BillingInvoiceStateService } from '@dougs/subscription/shared';
import { Task, TaskMetadata } from '@dougs/task/dto';
import { UserTasksStateService } from '@dougs/task/shared';
import { UpdatePaymentCardModalComponent } from '@dougs/task/task-actions';
import { TaskService } from '@dougs/task/ui';

@Injectable()
export class UpdatePaymentCardComponentComponentService {
  private updateCardTask?: Task<TaskMetadata>;

  refreshPendingBillingInvoices$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((activeCompany) => this.billingInvoiceStateService.refreshPendingBillingInvoices(activeCompany.id)),
  );

  daysBeforePayment$: Observable<string | null> = combineLatest([
    this.billingInvoiceStateService.pendingBillingInvoices$,
    this.userTasksStateService.tasks$,
  ]).pipe(
    map(([pendingBillingInvoices, tasks]) => {
      this.updateCardTask = tasks.find((task) => task.code === 'customer:updatePaymentCard');
      return this.computeDaysBeforePayment(pendingBillingInvoices?.[0]);
    }),
  );

  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly billingInvoiceStateService: BillingInvoiceStateService,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly modalService: ModalService,
    private readonly taskService: TaskService,
  ) {}

  async onModifyPaymentCard(): Promise<void> {
    if (!this.updateCardTask) {
      return;
    }

    const succeeded: boolean | null | undefined = (
      await lastValueFrom(
        this.modalService.open<boolean>(UpdatePaymentCardModalComponent, {
          disableClose: true,
          data: {
            task: this.updateCardTask,
          },
        }).afterClosed$,
      )
    ).data;

    if (succeeded) {
      await this.taskService.completeTask(this.updateCardTask);
    }
  }

  private computeDaysBeforePayment(nextPendingBillingInvoice: PendingBillingInvoice | undefined): string | null {
    if (!nextPendingBillingInvoice) {
      return null;
    }

    const diff: number = differenceInDays(new Date(nextPendingBillingInvoice.issuableAt), new Date());

    if (diff === 0) {
      return `aujourd'hui`;
    }
    if (diff === 1) {
      return `demain`;
    }
    if (diff <= 5) {
      return `dans ${diff} jours`;
    }

    return null;
  }
}
