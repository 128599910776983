<nav
  class="navigation px-12 py-24"
  [ngClass]="{
    'slided-in': slidedIn,
    'is-compact': isCompact,
    'xlarge-breakdown': xlargeBreakdown,
    'large-breakdown': !xlargeBreakdown,
  }"
  dougsClickOutside
  (clickOutside)="clickOutside.emit()"
>
  <h5 class="mb-24 navigation__title" [class.ml-12]="titleMarginLeft">
    <ng-content select="[title]" />
  </h5>

  <ng-content select="[prefix]" />

  <div class="navigation__menu">
    <ng-content select="[scrollable-prefix]" />
    @for (navGroup of navGroups; track $index) {
      @if (!$first && !navGroup.disableDivider) {
        <div class="navigation__divider px-8 py-24">
          <dougs-divider />
        </div>
      }
      @if (navGroup.title) {
        <p class="tiny color-gray-350 p-12">{{ navGroup.title }}</p>
      }
      @for (navElement of navGroup.navElements; track navElement.label) {
        @if (navElement.shouldShow | async) {
          <dougs-navigation-item (hideMenu)="hideMenu.emit()" [navElement]="navElement" />
        }
      }
    }
    <ng-content select="[scrollable-suffix]" />
  </div>

  <ng-content select="[suffix]" />
</nav>
