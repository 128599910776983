<ng-content />
<div #sidebarMenu [ngClass]="{ 'has-scrollbar': hasScrollbar }" class="menu py-12">
  <ng-content select="dougs-sidebar-menu-item" />
</div>
<ng-content select="[dougs-sidebar-menu-bottom]" />
<div class="logo p-16">
  <dougs-logo
    [compact]="isCompact"
    [width]="isCompact ? 24 : 95"
    [logoName]="logoName || ''"
    (click)="goToHomePage()"
  />
  <dougs-presence-members [isCompact]="isCompact" />
</div>
