import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CompanyStateService } from '@dougs/company/shared';
import { AppConfig } from '@dougs/core/config';
import { ReplaceActiveCompanyIdInUrlPipe, RoutingPipe, URL } from '@dougs/core/routing';
import { AvatarComponent, CopyToClipboardDirective, TooltipDirective } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { UserMenuComponentService } from '../../../../services/user-menu.component.service';

@Component({
  selector: 'dougs-user-menu-admin-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent,
    ReplaceActiveCompanyIdInUrlPipe,
    RoutingPipe,
    RouterLink,
    CopyToClipboardDirective,
    TooltipDirective,
  ],
  templateUrl: './user-menu-admin-dropdown.component.html',
  styleUrls: ['../user-menu-dropdown.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuAdminDropdownComponent {
  constructor(
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
    public readonly userMenuComponentService: UserMenuComponentService,
  ) {}

  protected readonly URL = URL;
  protected readonly legacyApiServerUrl = AppConfig.settings.legacyApiServerUrl;
}
