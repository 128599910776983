import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router, RouterLinkActive, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountingOnboardingStateService } from '@dougs/accounting/onboarding/shared';
import { CompanyStateService } from '@dougs/company/shared';
import { RoutingPipe, URL } from '@dougs/core/routing';
import { BadgeComponent, ClickOutsideDirective } from '@dougs/ds';
import { NotificationStateService } from '@dougs/notifications/shared';
import { NotValidatedOperationsStateService } from '@dougs/operations/shared';
import { AccountingProductsTourService } from '@dougs/revenue/products-tour/shared';
import { ProductTourDirective } from '@dougs/revenue/products-tour/ui';
import { SERVICE_STATE } from '@dougs/revenue/services/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { ServicesAccessService } from '@dougs/services/shared';
import { ReferralAccessService } from '@dougs/subscription/shared';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { CockpitStateService } from '@dougs/task/shared';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';
import { ChecklistComponent } from '../checklist/checklist.component';
import { SidebarCompactComponentService } from '../services/sidebar-compact.component.service';
import { SidebarMenuEventsService } from '../services/sidebar-menu-events.service';
import { SidebarModuleAccessComponentService } from '../services/sidebar-module-access.component.service';
import { SidebarComponentService } from '../services/sidebar.component.service';
import { UserMenuComponentService } from '../services/user-menu.component.service';
import { SidebarMenuItemComponent } from '../sidebar-menu/sidebar-menu-item.component';
import { SidebarMenuSeparatorDirective } from '../sidebar-menu/sidebar-menu-separator.directive';
import { SidebarMenuComponent } from '../sidebar-menu/sidebar-menu.component';
import { AccountingSurveyReminderComponent } from './accounting-survey-reminder/accounting-survey-reminder.component';
import { CompanyCreationXsellComponent } from './company-creation-xsell/company-creation-xsell';
import { TrialComponent } from './trial/trial.component';
import { UpdatePaymentCardComponent } from './update-payment-card/update-payment-card.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

@Component({
  selector: 'dougs-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SidebarMenuComponent,
    SidebarMenuItemComponent,
    RouterModule,
    BadgeComponent,
    CompanyCreationXsellComponent,
    TrialComponent,
    AccountingSurveyReminderComponent,
    UserMenuComponent,
    SidebarMenuSeparatorDirective,
    ClickOutsideDirective,
    RoutingPipe,
    ProductTourDirective,
    ChecklistComponent,
    UpdatePaymentCardComponent,
  ],
  providers: [
    SidebarComponentService,
    SidebarModuleAccessComponentService,
    SidebarCompactComponentService,
    UserMenuComponentService,
  ],
})
export class SidebarComponent implements OnInit {
  @Input()
  @HostBinding('class.is-compact')
  isCompact = false;

  @Input()
  @HostBinding('class.is-control-panel-opened')
  isControlPanelOpened = false;

  isChecklistOpened = false;
  isMenuOpened = false;
  USER_FLAG = USER_FLAG;
  public readonly URL = URL;
  public readonly SERVICE_STATE = SERVICE_STATE;

  onToggleSidebarMenu$: Observable<void> = this.sidebarMenuEventsService.sidebarMenuToggled$.pipe(
    map(() => this.openMenu()),
  );

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
    public readonly companyServicesStateService: CompanyServicesStateService,
    public readonly sidebarComponentService: SidebarComponentService,
    public readonly sidebarCompactComponentService: SidebarCompactComponentService,
    public readonly notValidatedOperationsStateService: NotValidatedOperationsStateService,
    public readonly sidebarModuleAccessComponentService: SidebarModuleAccessComponentService,
    public readonly cockpitStateService: CockpitStateService,
    public readonly notificationStateService: NotificationStateService,
    public readonly accountingOnboardingStateService: AccountingOnboardingStateService,
    public readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    public readonly router: Router,
    public readonly accountingProductsTourService: AccountingProductsTourService,
    public readonly sidebarMenuEventsService: SidebarMenuEventsService,
    public readonly referralAccessService: ReferralAccessService,
    public readonly servicesAccessService: ServicesAccessService,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'gray');
  }

  openMenu(e?: Event): void {
    e?.stopPropagation();
    this.isMenuOpened = !this.isMenuOpened;
    this.cdr.markForCheck();
  }

  onClickOutsideMenu(): void {
    if (this.isMenuOpened) {
      this.isMenuOpened = false;
      this.cdr.markForCheck();
    }
  }

  isRouterActiveAndNotificationClosed(routerLinkActive: RouterLinkActive): boolean {
    return routerLinkActive.isActive;
  }

  toggleChecklist(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.isChecklistOpened = !this.isChecklistOpened;
    this.sidebarComponentService.sendClickMenuEvent('Checklist');
  }

  closeChecklist(): void {
    this.isChecklistOpened = false;
  }
}
