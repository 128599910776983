import { CommonModule } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ClickOutsideDirective, DividerComponent } from '@dougs/ds';
import { NavGroup } from '../../dto';
import { NavElementComponent } from '../navigation-item/navigation-item.component';

@Component({
  selector: 'dougs-navigation',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective, NavElementComponent, DividerComponent],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  @Input() slidedIn = false;
  @Input() isCompact = false;
  @Input() titleMarginLeft = false;
  @Input({ transform: booleanAttribute }) xlargeBreakdown = false;
  @Input() navGroups: NavGroup[] = [];

  @Output() clickOutside: EventEmitter<void> = new EventEmitter<void>();
  @Output() hideMenu: EventEmitter<void> = new EventEmitter<void>();
}
